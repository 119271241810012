import React, { useEffect, useState } from 'react';
import { DateTime, Interval } from 'luxon';

import './App.css';
import SpinnerElement from './SpinnerElement'
import { Badge } from 'react-bootstrap';

// Can't use, blame microsoft
//import mcLogo from './mc-logo.png';
import mcLogo from './TAMPERE.png';
import jumbotronImage from './jumbotron-bg.jpg';

import axios from 'axios'

const convertMCAPIdata = (data) => {
    return {
        isOnline: data.online,
        version: data.server.name,
        onlinePlayers: data.players.now,
        maxPlayers: data.players.max,
        motd: data.motd,
        //latency: data.server.protocol,
        lastOnline: DateTime.fromSeconds(Number(data.last_online)).toLocaleString(DateTime.DATETIME_FULL),
        updated: DateTime.fromSeconds(Number(data.last_updated)).toLocaleString(DateTime.DATETIME_FULL)
    }
}

const convertValheimData = (data) => {
    /**
     * {
        "last_status_update": "2021-03-07T21:42:46.307232+00:00",
        "error": "timeout('timed out')"
        }
     */
    let result = {
        running: false,
        last_status_update: null,
        server_name: null,
        player_count: 0,
        players: []
    }
    if (typeof data === 'string') {
        try {
            data = JSON.parse(data)
        } catch (err) {
            console.error("Error while parsing data", data)
        }
    }

    if (data != null) {
        result.running = data.error == null
        const propsToHandle = [
            "server_name",
            "last_status_update",
            "players",
            "player_count"
        ]

        propsToHandle.forEach((prop) => {
            if (Object.prototype.hasOwnProperty.call(data, prop)) {
                result[prop] = data[prop]
            }
        })
    }

    return result
}

const App = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [requestStatus, setRequestStatus] = useState(0);
    const [serverStatus, setServerStatus] = useState(null);
    const [intervalTimer, setIntervalTimer] = useState(null);
    const [currentTime, setCurrentTime] = useState(DateTime.now())

    const [valheimData, setValheimData] = useState(null)

    const [terrariaData, setTerrariaData] = useState(null)

    const [palworldData, setPalworldData] = useState(null)

    const COL_SIZE = 3;

    const minecraftStatusQuery = () => {
        setRequestStatus(1)
        setIsLoading(true)
        return axios.get("https://mcapi.us/server/status?ip=arbitor.ddns.net").then((res) => {
            setServerStatus(convertMCAPIdata(res.data))
        }).catch((err) => {
            console.error(err)
            setServerStatus(null)
        })
    }

    const valheimStatusQuery = () => {
        setRequestStatus(1)
        setIsLoading(true)
        return axios.get("https://arbitor.ddns.net/valheim/status").then((res) => {
            setValheimData(convertValheimData(res.data))
        }).catch((err) => {
            console.error(err)
            setValheimData(convertValheimData())
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const terrariaStatusQuery = () => {
        setRequestStatus(1)
        setIsLoading(true)
        return axios.get("https://arbitor.ddns.net/terraria-status").then((res) => {
            setTerrariaData(res.data)
        }).catch((err) => {
            console.error(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const palworldStatusQuery = () => {
        setRequestStatus(1)
        setIsLoading(true)
        return axios.get("https://arbitor.ddns.net/palworld-status").then((res) => {
            setPalworldData(res.data)
        }).catch((err) => {
            console.error(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (requestStatus == 0) {
            let promises = [
                minecraftStatusQuery(),
                valheimStatusQuery(),
                terrariaStatusQuery(),
                palworldStatusQuery()
            ]
            Promise.all(promises)
        }

        // Ran only once
        if (intervalTimer === null) {
            setIntervalTimer(setInterval(() => {
                setCurrentTime(DateTime.now())
            }, 1000))

            setInterval(() => {
                // Toggle request status.
                setRequestStatus(0);
            }, 60000)
        }
    }, [requestStatus, intervalTimer])

    /**
     * Renders the current time.
     */
    const renderCurrentTime = () => {
        return (<b>
            Current time: {currentTime.setLocale('fi').toLocaleString(DateTime.DATETIME_FULL)}
        </b>)
    }

    /**
     * Renders the server state data.
     */
    const renderServerData = () => {
        let notAvailableMsg = "Ei saatavilla / Not available";
        if (serverStatus != null) {
            if (serverStatus.isOnline) {
                return (<>
                    <h5><i className='fas fa-check-square fa-lg'></i> <b>Server Version:</b>{serverStatus.version}</h5>
                    <h5><i className='fas fa-street-view fa-lg'></i> <b>Players:</b> {serverStatus.onlinePlayers + "/" + serverStatus.maxPlayers}</h5>
                    {/*<h5><i className='fas fa-signal fa-lg'></i> <b>Latency:</b>{serverStatus.latency + " ms"}</h5>*/}
                    <b>Updated:</b>{serverStatus.updated}
                </>)
            } else {
                return (<>
                    <h5><i className='fas fa-check-square fa-lg'></i> <b>Server Version:</b>{notAvailableMsg}</h5>
                    <h5><i className='fas fa-street-view fa-lg'></i> <b>Players:</b> {notAvailableMsg}</h5>
                    <b>Updated:</b>{serverStatus.updated}
                </>)
            }
        }
        return null;
    }


    const renderMinecraftRow2 = () => {
        return <div id="minecraft-server-info" className={"col-sm-" + COL_SIZE}>
            {renderServerData()}
        </div>
    }

    /**
     * Renders all the server status data.
     */
    const renderServerStatus = () => {
        let isOnline = serverStatus != null ? serverStatus.isOnline : false;
        let motd = serverStatus != null && serverStatus.motd != null ? serverStatus.motd : "Ei viestiä saatavilla. / No message available."
        return (
            <>
                <div id="minecraft-server-content" className={"col-sm-" + COL_SIZE}>
                    <h2>
                        {isOnline ?
                            <>
                                <Badge variant="success"></Badge>
                                <div style={{ display: "inline-block" }} id="online-state">
                                    MINECRAFT - ONLINE
                                </div>
                            </> :
                            <>
                                <Badge variant="danger"></Badge>
                                <div style={{ display: "inline-block" }} id="online-state">
                                    MINECRAFT - OFFLINE
                                </div>
                            </>
                        }
                    </h2>
                    <h4>
                        <Badge variant="primary"></Badge>
                        <i className="fas fa-comment-alt fa-"></i> Päivän viesti / Message of the Day:
                    </h4>
                    <Badge variant="secondary"></Badge>
                    <p className="lead" id="motd">
                        {motd}
                    </p>
                </div>

            </>)
    }

    const renderValheimPlayerList = (playerList) => {
        if (Array.isArray(playerList) && playerList.length > 0) {
            return <ul>
                <li>Name - Score - Minutes online</li>
                {playerList.map((player, i) => {
                    if (player.name == "" || !player.name) {
                        player.name = "[No player name]"
                    }
                    if (player.duration > 0 && !player.hasMinutes) {
                        player.hasMinutes = true
                        player.duration = Math.round(player.duration / 60)
                    }
                    return <li key={i}>{player.name} - {player.score} - {player.duration}</li>
                })}
            </ul>
        } else {
            return <h3>No players</h3>
        }
    }

    const renderValheimRow2 = () => {
        if (valheimData != null) {
            return <div id="valheim-server-info" className={"col-sm-" + COL_SIZE}>
                <h3>Players: {valheimData.player_count} </h3>
                {renderValheimPlayerList(valheimData.players)}
            </div>
        } else {
            return <h3>No data to show</h3>
        }

    }

    const renderValheimServerStatus = () => {
        if (valheimData == null) {
            return <h4>Valheim not found</h4>
        } else {
            return (
                <>
                    <div id="terraria-server-content" className={"col-sm-" + COL_SIZE}>
                        <h2>
                            {valheimData.running ?
                                <>
                                    <Badge variant="success"></Badge>
                                    <div style={{ display: "inline-block" }} id="online-state">
                                        VALHEIM - ONLINE
                                    </div>
                                </> :
                                <>
                                    <Badge variant="danger"></Badge>
                                    <div style={{ display: "inline-block" }} id="online-state">
                                        VALHEIM - OFFLINE
                                    </div>
                                </>
                            }
                        </h2>
                        <h4>
                            <Badge variant="primary"></Badge>
                            <i className="fas fa-comment-alt fa-"></i>{valheimData.server_name}
                        </h4>
                        <Badge variant="secondary"></Badge>
                        <p className="lead" id="motd">
                            Viimeisin päivitys: {DateTime.fromISO(valheimData.last_status_update).toLocaleString(DateTime.DATETIME_FULL)}

                        </p>
                        <h5>Vaadittavat pluginit:</h5>
                        {/*  <span style={{display: 'block'}}><a href="https://valheim.thunderstore.io/package/denikson/BepInExPack_Valheim/">Download BepInEx</a></span> */}
                        <span style={{ display: 'block' }}><a href="https://arbitor.ddns.net/plugins.zip">Download Plugins (ZIP-file, includes BepInExx)</a></span>
                    </div>

                </>)
        }
    }

    const renderTerrariaServerStatus = () => {
        if (terrariaData == null) {
            return <h4>Terraria not found</h4>
        } else {
            return (
                <>
                    <div id="terraria-server-content" className={"col-sm-" + COL_SIZE}>
                        <h2>
                            {terrariaData.isRunning ?
                                <>
                                    <Badge variant="success"></Badge>
                                    <div style={{ display: "inline-block" }} id="online-state">
                                        TERRARIA - ONLINE
                                    </div>
                                </> :
                                <>
                                    <Badge variant="danger"></Badge>
                                    <div style={{ display: "inline-block" }} id="online-state">
                                        TERRARIA - {terrariaData.status.toUpperCase()}
                                    </div>
                                </>
                            }
                        </h2>
                        <h4>
                            <Badge variant="primary"></Badge>
                            <i className="fas fa-comment-alt fa-"></i>{terrariaData.server_name}
                        </h4>
                        <Badge variant="secondary"></Badge>
                        <p className="lead" id="motd">
                            Viimeisin päivitys: {DateTime.fromISO(terrariaData.last_status_update).toLocaleString(DateTime.DATETIME_FULL)}
                        </p>
                    </div>

                </>)
        }
    }

    const renderTerrariaRow2 = () => {
        return <div id="terraria-server-info" className={"col-sm-" + COL_SIZE}>
        </div>
    }

    const rendePalworldServerStatus = () => {
        if (palworldData == null) {
            return <h4>Palworld not found</h4>
        } else {
            return (
                <>
                    <div id="palworld-server-content" className={"col-sm-" + COL_SIZE}>
                        <h2>
                            {palworldData.isRunning ?
                                <>
                                    <Badge variant="success"></Badge>
                                    <div style={{ display: "inline-block" }} id="online-state">
                                        PALWORLD - ONLINE
                                    </div>
                                </> :
                                <>
                                    <Badge variant="danger"></Badge>
                                    <div style={{ display: "inline-block" }} id="online-state">
                                        PALWORLD - {palworldData.status.toUpperCase()}
                                    </div>
                                </>
                            }
                        </h2>
                        <h4>
                            <Badge variant="primary"></Badge>
                            <i className="fas fa-comment-alt fa-"></i>{palworldData.server_name}
                        </h4>
                        <Badge variant="secondary"></Badge>
                        <p className="lead" id="motd">
                            Viimeisin päivitys: {DateTime.fromISO(palworldData.last_status_update).toLocaleString(DateTime.DATETIME_FULL)}
                        </p>
                    </div>

                </>)
        }
    }

    const renderPalworldRow2 = () => {
        if (palworldData != null) {
            return <div id="palworld-server-info" className={"col-sm-" + COL_SIZE}>
                <Badge variant="success"></Badge>
                <div style={{ display: "inline-block" }} id="online-state">
                    IP - {palworldData.ip}
                </div>
                <br />
                <Badge variant="danger"></Badge>
                <div style={{ display: "inline-block" }} id="online-state">
                    PASSWORD - {palworldData.password}
                </div>

            </div>
        }
        return <div id="palworld-server-info" className={"col-sm-" + COL_SIZE}>
        </div>
    }

    return (
        <>
            <div id="container" className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="jumbotron" style={{ backgroundImage: `url(${jumbotronImage})` }}>
                            <img src={mcLogo} height="240" alt="Minecraft" />
                            <h1>
                                <Badge variant="success">Tervetuloa Alien Palvelimelle!</Badge>
                            </h1>
                            <h1>
                                <Badge variant="success">Welcome to Alien Server!</Badge>
                            </h1>
                        </div>
                    </div>
                    <div id="server-content-header" className="col-sm-12">
                        <h3>
                            <Badge variant="primary">
                                <div>
                                    <i className="fas fa-server"></i> Palvelimen tila / Server status
                                </div>
                                <div>
                                    <i className="fa-clock-o"></i> {renderCurrentTime()}
                                </div>
                            </Badge>
                        </h3>
                        <div className="row">
                            <SpinnerElement enabled={isLoading}>
                                {renderServerStatus()}
                                {renderTerrariaServerStatus()}
                                {rendePalworldServerStatus()}
                                {renderValheimServerStatus()}
                                {renderMinecraftRow2()}
                                {renderTerrariaRow2()}
                                {renderPalworldRow2()}
                                {renderValheimRow2()}

                            </SpinnerElement>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};


export default App;
