import { Spinner } from "react-bootstrap";

const SpinnerElement = (props) => {

    const { isEnabled } = props;

    if (isEnabled) {
        return <Spinner animation="border" role="status"></Spinner>
    }
    return props.children
};

export default SpinnerElement;